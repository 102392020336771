<ng-container *transloco="let t">
  <fuse-card class="flex flex-col w-full p-8 pb-4 filter-listing">
    <div class="text-2xl font-semibold leading-tight">
      {{ t('jobCardFareDetail.fareDetails') }}
    </div>

    <!-- Tariff -->
    <!--        <div class="text-xl font-semibold leading-tight mt-4 ml-1">{{ t('jobCardFareDetail.tariff') }}</div>-->
    <div class="flex items-center leading-none mt-4">
      <div class="w-full grid grid-cols-12 gap-x-1 mt-4">
        <ng-container *ngIf="fare.elements | fareGroup as fareGroups">
          <!-- iterate through orderedfaregroup and output groups in order -->
          <ng-container *ngFor="let groupType of orderedFareGroupTypes">
            <!-- Tariff -->
            <ng-container
              *ngIf="fareGroups[groupType | uppercase] as fareGroupData"
            >
              <ng-container
                *ngTemplateOutlet="
                  fareGroup;
                  context: { $implicit: fareGroupData, title: groupType }
                "
              >
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container>
            <div class="col-span-12 my-4 -mt-3 -mx-8 border-b"></div>

            <!-- Total -->
            <div
              class="col-span-10 self-center font-semibold tracking-tight text-right text-sm text-secondary"
            >
              {{ t('jobCardFareDetail.totalFare') }}
            </div>
            <div class="col-span-2 text-right font-semibold">
              {{ fareGroups | totalFromFareGroups | currency }}
            </div>
            <div class="col-span-12 my-4 -mx-8"></div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </fuse-card>
  <!-- FARE GROUP TEMPLATE -->
  <ng-template #fareGroup let-fareGroup let-title="title">
    <div class="col-span-6 font-medium text-medium text-secondary">
      {{ t('jobCardFareDetail.' + (title | lowercase)) }}
    </div>
    <div class="col-span-2 font-medium text-medium text-secondary text-right">
      {{ t('jobCardFareDetail.rate') }}
    </div>
    <div class="col-span-2 font-medium text-medium text-secondary text-right">
      {{ t('jobCardFareDetail.tax') }}
    </div>
    <div class="col-span-2 font-medium text-medium text-secondary text-right">
      {{ t('jobCardFareDetail.total') }}
    </div>

    <!-- Divider -->
    <div class="col-span-12 my-4 -mx-8 border-b"></div>

    <!-- Item -->
    <ng-container *ngFor="let element of fareGroup; last as isLast">
      <div class="col-span-6">
        <div class="font-medium text-md">
          {{ element.title | uppercase }}
        </div>
        <div
          *ngIf="element.description"
          class="mt-2 text-md text-secondary"
          [innerHtml]="element.description | marked"
        ></div>

        <div
          class="mt-2 text-md text-secondary flex flex-wrap gap-1"
          *ngIf="element?.distance || element?.duration"
        >
          <div *ngIf="element?.distance" class="whitespace-nowrap">
            Dist: {{ element.distance
            }}{{ ' ' + element.distanceUnit | lowercase
            }}{{ element.duration ? ',' : '' }}
          </div>
          <div *ngIf="element?.duration" class="whitespace-nowrap">
            Time:
            {{ element.duration | timeConverter }}
          </div>
        </div>
      </div>
      <div class="col-span-2 self-center text-right">
        {{ element.amountTaxExclusive | currency }}
      </div>
      <div class="col-span-2 self-center text-right">
        {{ element.totalTax | currency }}
      </div>
      <div class="col-span-2 self-center text-right">
        {{ element.amount | currency }}
      </div>

      <!-- Divider -->
      <div class="col-span-12 my-4 border-b -mx-8"></div>
    </ng-container>
    <ng-container *ngIf="fareGroup | fareGroupTotals as totalSummary">
      <div
        class="col-span-10 self-center font-medium tracking-tight text-right text-sm text-secondary"
      >
        {{ t('jobCardFareDetail.subtotal') }}
      </div>
      <div class="col-span-2 text-right">
        {{ totalSummary.subTotal | currency }}
      </div>

      <!-- Divider -->
      <div class="col-span-12 my-3 -mx-8"></div>
      <div
        class="col-span-10 self-center font-medium tracking-tight text-right text-sm text-secondary"
      >
        {{ t('jobCardFareDetail.taxTotal') }}
      </div>
      <div class="col-span-2 text-right">
        {{ totalSummary.totalTax | currency }}
      </div>

      <!-- Divider -->
      <div class="col-span-12 my-3 -mx-8"></div>

      <!-- Total -->
      <div
        class="col-span-10 self-center font-medium tracking-tight text-right text-sm text-secondary"
      >
        {{ t('jobCardFareDetail.totalTitle', { title: title | uppercase }) }}
      </div>
      <div class="col-span-2 text-right font-medium">
        {{ totalSummary.total | currency }}
      </div>
      <div class="col-span-12 mb-12"></div>
    </ng-container>
  </ng-template>
</ng-container>
