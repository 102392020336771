export const environment = {
  production: true,
  hmr: false,
  host: 'https://production-tapi-live.us1.fleet.com.au',
  googleApiKey: 'AIzaSyAWX3XeX4Q_gAeONVQcSUkOH6e2xEdRmgg',
  minTokenExpiry: 60,
  fleetProduct: 'TRAVELLER',
  envName: 'prod-us1',
  xApiKey: 'pk_live_d41d8cd98f00b204e9800998ecf8427e',
  socketHost: 'wss://socket-awsproduction.ingogo.mobi/socket',
  socketTopicPrefix: '/topic/live/core/v3/',
  socketAcknowledge:
    'https://socket-awsproduction.ingogo.mobi/socket/client/{{clientId}}/acknowledge/',
  braintreeWebClientToken: 'production_bnmpbjsp_h57c483mc8x86myw',
  braintreeMode: '',
  tasHost: 'https://production-svc-tas.us1.fleet.com.au/tas/api/live/v1',
};
