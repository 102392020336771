import { IsActiveMatchOptions } from '@angular/router';
import {
  TravellerModel,
  TravellerProfileModel,
  ServiceLineModel,
  ServiceClassModel,
  JobDriverModel,
  VehicleModel,
} from './api-model';

import {
  DocumentModel,
  PaymentMethodModel,
  OrganisationGroupModel,
  LocationModel,
  JobTravellerModel,
  JobProgressModel,
  JobSearchResultModel,
} from './api-model';
export interface SearchResult {
  params: any;
  totalCount: number;
  totalPages: number;
  currentPage: number;
  error: any;
  searching: boolean;
}

export interface ThirdPartyQuoteRequest {
  utm_source: string;
  serviceType: string;
  requestPickup: string;
  pickupLatitude: string;
  pickupLongitude: string;
  dropoffLatitude: string;
  dropoffLongitude: string;
  pickupPlace: string;
  dropoffPlace: string;
}

export interface DriverDocument {
  type: string;
  currentVersion: DocumentModel;
  oldVersions: DocumentModel[];
  versions: DocumentModel[];
  driverId: string;
}

export interface DriverPaymentMethodModel extends PaymentMethodModel {
  isDefault: boolean;
  isDisbursementAccountBillingDefault?: boolean;
}

export interface OperatorPaymentMethodModel extends PaymentMethodModel {
  isDefault: boolean;
}

export interface OrganisationGroupWithChildren extends OrganisationGroupModel {
  childOrganisations: Array<any>;
  selected: boolean;
}

import { EntityType } from './enums';

export interface EntityServiceConfiguration {
  entityId: string;
  entityType: EntityType;
  defaultContactNumber: string;
  defaultLocation: any;
  entityGroupId: string;
  entityNetworkId: string;
  entityUserId: string;
  entityOrganisationId: string;
  entityLocation: LocationModel;
  dispatchProviderId: string;
}

export interface Traveller {
  traveller: JobTravellerModel;
  organisationStaffId: string;
  type: TravellerType;
}

export enum TravellerType {
  ORGANISTION_GUEST = 'ORGANISTION_GUEST',
  ORGANISTION_STAFF = 'ORGANISTION_STAFF',
  TRAVELLER = 'TRAVELLER',
  GUEST = 'GUEST',
}

export interface GroupHierarchyItem {
  displayName: string;
  logo: string;
  groupId: string;
  type: string;
  parent: GroupHierarchyItem;
  link: string;
}
export interface TravellerWithSelectedProfile {
  traveller: TravellerModel;
  profile: TravellerProfileModel;
  entityType:
    | 'ORGANISATION_STAFF'
    | 'ORGANISATION_GUEST'
    | 'TRAVELLER'
    | 'GUEST';
}
export interface FuseNavigationItem {
  id?: string;
  title?: string;
  subtitle?: string;
  type: 'aside' | 'basic' | 'collapsable' | 'divider' | 'group' | 'spacer';
  hidden?: (item: FuseNavigationItem) => boolean;
  active?: boolean;
  disabled?: boolean;
  tooltip?: string;
  link?: string;
  externalLink?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top' | string;
  exactMatch?: boolean;
  groupBackground?: string;
  isActiveMatchOptions?: IsActiveMatchOptions;
  function?: (item: FuseNavigationItem) => void;
  classes?: {
    title?: string;
    subtitle?: string;
    icon?: string;
    wrapper?: string;
    host?: string;
  };
  icon?: string;
  badge?: {
    title?: string;
    classes?: string;
  };
  children?: FuseNavigationItem[];
  meta?: any;
  securityFunctionType?: string;
  securityAction?: string;
  securityFunctions?: string[];
  description?: string;
  fleetOnly?: boolean;
  ignoreTranslation?: boolean;
}

export interface JobStatusUpdateAndProgress {
  latestJobProgress: JobProgressModel;
  latestStatusUpdate: JobSearchResultModel;
  statusColor: { borderColor: string; badgeColor: string };
}

export interface ServiceClassWithLines {
  class: ServiceClassModel;
  lines: ServiceLineModel[];
}

export interface ServiceClassTypeWithLineTypes {
  serviceClass: string;
  serviceLines: string[];
}

export interface CurrencyMaskConfig {
  align: string;
  allowNegative: boolean;
  allowZero: boolean;
  decimal: string;
  precision: number;
  prefix: string;
  suffix: string;
  thousands: string;
  nullable: boolean;
  min?: number;
  max?: number;
  inputMode?: CurrencyMaskInputMode;
  label?: string;
  currencyCode?: string;
}
export enum CurrencyMaskInputMode {
  FINANCIAL,
  NATURAL,
}
export interface ActiveJobDisplay {
  slaColor?: string;
  timeUntilNextSla?: string;
  serviceClassIcon?: string;
  serviceLineIcon?: string;
  startLocality?: string;
  endLocality?: string;
  networkService?: string;
  slaStartTime?: string;
  slaStartTimeAMPM?: string;
  slaTextColor?: string;
  slaLevel?: number;
  millisecondsTillSLA3?: number;
  jobId?: string;
  networkConditionIds?: string[];
  travellerFirstName?: string;
  travellerLastName?: string;
  organisationName?: string;
  driverFirstName?: string;
  driverTaxiAuthorityNumber?: string;
  plateNumber?: string;
  eta?: string;
  distance?: string;
  status: string;
  numberOfTravellers?: number;
  expiresIn?: string;
  outerRingStroke?: string;
  startTime?: string;
  delayedBy?: string;
  traveller?: JobTravellerModel;
  driver?: JobDriverModel;
  vehicle?: VehicleModel;
  chatAvailable?: boolean;
  callAvailable?: boolean;
  statusBackground?: string;
  statusColor?: string;
  layoutTemplate?: string;
  statusFlashing?: boolean;
  job?: JobSearchResultModel;
}
